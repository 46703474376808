import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import withAuth from '@hocs/withAuth'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuth } = rest

  return (
    <Route
      render={props => (isAuth ? <Component {...props} /> : <Redirect to="/login" />)}
      {...rest}
    />
  )
}

export default withAuth(ProtectedRoute)
