import React, { createContext, useCallback, useState, memo, useReducer, useEffect } from 'react'
import moment from 'moment-timezone'

import reducer, { initialState, SET_HEADER_TITLE, SET_CAMPAIGN_NAME, TOGGLE_MENU } from './reducer'

const AppContext = createContext()

const AppProvider = memo(({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [searchBrandQuery, setSearchBrandQuery] = useState('')
  const [userTimezoneName, setUserTimezoneName] = useState('')
  const [userTimezoneOffset, setUserTimezoneOffset] = useState(0)

  useEffect(() => {
    const storedTimeZoneName = localStorage.getItem('userTimezoneName')
    const timezoneName = storedTimeZoneName ? storedTimeZoneName : moment.tz.guess()
    const timezoneOffset = storedTimeZoneName
      ? moment.tz(storedTimeZoneName)._offset
      : moment.tz(timezoneName)._offset
    setUserTimezoneName(timezoneName)
    setUserTimezoneOffset(timezoneOffset)
  }, [])

  const handleUserTimezoneChange = timezoneName => {
    setUserTimezoneName(timezoneName)
    setUserTimezoneOffset(moment.tz(timezoneName)._offset)
  }

  const getTimezoneWithOffset = (field, timeFormat) => {
    return moment(
      moment(field)
        .utc()
        .add(userTimezoneOffset, 'minutes'),
    ).format(timeFormat)
  }

  const setHeaderTitle = useCallback(
    title => dispatch({ type: SET_HEADER_TITLE, payload: title }),
    [],
  )

  const setCampaignName = useCallback(
    campaignName => dispatch({ type: SET_CAMPAIGN_NAME, payload: campaignName }),
    [],
  )

  const toggleMenu = useCallback(() => dispatch({ type: TOGGLE_MENU }), [])

  return (
    <AppContext.Provider
      value={{
        ...state,
        setHeaderTitle,
        setCampaignName,
        toggleMenu,
        searchBrandQuery,
        setSearchBrandQuery,
        userTimezoneName,
        handleUserTimezoneChange,
        getTimezoneWithOffset,
        userTimezoneOffset,
      }}
    >
      {children}
    </AppContext.Provider>
  )
})

export { AppContext, AppProvider }
