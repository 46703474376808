import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { AppContext } from '@contexts/AppContext'
import { TimezoneIcon } from '@assets/icons'

const Header = () => {
  const { headerTitle, userTimezoneName } = useContext(AppContext)

  return (
    <div className="layout__main-header">
      <span className="layout__main-title">{headerTitle}</span>
      <div className="layout__timezone">
        <NavLink className="layout__timezone-link" title="Change Timezone" to="/profile">
          <TimezoneIcon className="layout__timezone-icon" />
          {userTimezoneName}
        </NavLink>
      </div>
    </div>
  )
}

export default Header
