import React from 'react'

import { BrandInfoConsumer } from '@contexts/BrandInfoContext'

export default WrappedComponent => props => {
  const Hoc = (
    <BrandInfoConsumer>
      {brandInfoState => <WrappedComponent {...props} brandInfo={brandInfoState} />}
    </BrandInfoConsumer>
  )

  return Hoc
}
