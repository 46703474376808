import React from 'react'
import get from 'lodash/get'
import { Mutation } from 'react-apollo'
import { toast } from 'react-toastify'

import SamMasquarade from '@graphql/SamMasquarade'

import { VideoCameraIcon } from '@assets/icons'

const Sam = () => {
  const _handleClick = samMasquerade => () => {
    const windowReference = window.open()

    samMasquerade()
      .then(data => {
        const url = get(data, 'data.samMasquerade.url')
        const token = get(data, 'data.samMasquerade.token')

        if (token && url) {
          windowReference.location = `${url}/${token}`
          return
        }
        toast.error('An error occured, invalid token or url')
      })
      .catch(() => {
        windowReference.close()
        toast.error('An error occured, try again')
      })
  }

  return (
    <Mutation mutation={SamMasquarade}>
      {(samMasquerade, { loading }) => (
        <div className="layout__menu-link pointer" onClick={_handleClick(samMasquerade)}>
          <VideoCameraIcon className="layout__menu-link-icon" />
          <div className="layout__menu-link-title">{loading ? 'Loading SAM...' : 'SAM'}</div>
        </div>
      )}
    </Mutation>
  )
}

export default Sam
