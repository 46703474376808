import React from 'react'

import withBrandInfo from '@hocs/withBrandInfo'

export const Header = ({ brandInfo: { name, logoUrl } }) => (
  <header className="layout__menu-header">
    {logoUrl ? (
      <img alt="Brand logo" className="brand__logo" src={logoUrl} />
    ) : (
      <div className="layout__menu-header-title">{name}</div>
    )}
  </header>
)

export default withBrandInfo(Header)
