import React, { PureComponent } from 'react'
import { getFaviconURL, setFaviconTag } from '@utils/faviconActions'
import { withApollo } from 'react-apollo'

import BrandInfo from '@graphql/Brand/BrandInfo'

import withAuth from '@hocs/withAuth'
import isPublicSchema from '@utils/isPublicSchema'
import { mapFeaturesValues } from '@utils/brand'
import SpinnerPage from '@components/SpinnerPage'

export const BrandInfoContext = React.createContext()
export class BrandInfoProvider extends PureComponent {
  state = {
    originalFaviconUrl: '/favicon.ico',
    isMounted: false,
    name: 'Portal - Brandactif',
    logoUrl: '',
    features: {},
    availableCampaignTypes: {},
  }

  componentDidMount = () => {
    this.setState({ isMounted: true, originalFaviconUrl: getFaviconURL() }, this._getBrandInfo)
  }

  componentDidUpdate(_, prevState) {
    const { originalFaviconUrl, name } = this.state

    if (originalFaviconUrl !== prevState.originalFaviconUrl) {
      this._resetFavicon()
    }

    if (name !== prevState.name) {
      this._setTitle(name)
    }
  }

  _resetFavicon = () => {
    const { originalFaviconUrl } = this.state

    setFaviconTag(originalFaviconUrl)
  }

  _setTitle = name => (document.title = `${name} - Brandactif`)

  _getBrandInfo = async () => {
    const { client, isAuth } = this.props

    if (!isAuth || isPublicSchema) {
      return
    }

    try {
      const {
        data: { brandInfo: { logoUrl, name, features, availableCampaignTypes } },
      } = await client.query({
        query: BrandInfo,
      })

      this.setState(state => ({
        availableCampaignTypes,
        logoUrl,
        name,
        features: mapFeaturesValues(JSON.parse(features), true, false),
        originalFaviconUrl: logoUrl || state.originalFaviconUrl,
      }))
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const { children } = this.props
    const { isMounted, logoUrl, name, features, availableCampaignTypes } = this.state

    if (!isMounted) {
      return <SpinnerPage />
    }

    return (
      <BrandInfoContext.Provider
        value={{
          availableCampaignTypes,
          features,
          logoUrl,
          name,
        }}
      >
        {children}
      </BrandInfoContext.Provider>
    )
  }
}

export default withApollo(withAuth(BrandInfoProvider))

const BrandInfoConsumer = BrandInfoContext.Consumer
export { BrandInfoConsumer }
