import React from 'react'
import { Spinner } from 'reactstrap'

export const SpinnerPage = ({ color = 'secondary' }) => (
  <div className="spinner-page">
    <Spinner color={color} />
  </div>
)

export default SpinnerPage
