import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { RetryLink } from 'apollo-link-retry'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'

import isPublicSchema from './isPublicSchema'

const baseURL = process.env.REACT_APP_API_URL

const httpLink = new RetryLink({
  attempts: {
    max: 3,
  },
}).split(
  () => isPublicSchema,
  new HttpLink({ uri: `${baseURL}/graphql/admin/api` }),
  new HttpLink({ uri: `${baseURL}/graphql/api` }),
)

const authLink = setContext(async (_, { headers }) => {
  const token = await localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : null,
    },
  }
})

const resetToken = onError(({ _, networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    localStorage.removeItem('token')
    window.location.reload()
  }
})

const link = authLink.concat(resetToken).concat(httpLink)

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})
