import React from 'react'

import { AuthConsumer } from '@contexts/AuthContext'

export default WrappedComponent => props => {
  const ConsumerHoc = (
    <AuthConsumer>{authState => <WrappedComponent {...props} {...authState} />}</AuthConsumer>
  )

  return ConsumerHoc
}
