import React, { PureComponent } from 'react'
import { toast } from 'react-toastify'

import AdminUser from '@graphql/User/AdminUser'
import User from '@graphql/User/User'
import isPublicSchema from '@utils/isPublicSchema'
import withAuth from '@hocs/withAuth'
import { withApollo } from 'react-apollo'
import SpinnerPage from '@components/SpinnerPage'

const ROLES = {
  ADMIN_ROLE: 'admin',
  DEFAULT_ROLE: 'default',
}

export const UserContext = React.createContext()
class UserProvider extends PureComponent {
  state = {
    error: false,
    fetching: true,
    isMounted: false,
    user: {
      roles: ROLES,
    },
  }

  componentDidMount = () => {
    const { userUuid } = this.props

    this.setState({ isMounted: true }, () => {
      if (userUuid) {
        this.setState({ fetching: true }, this._getUser)
      } else {
        this.setState({ fetching: false })
      }
    })
  }

  componentDidUpdate = prevProps => {
    const { userUuid } = this.props

    if (!prevProps.userUuid && userUuid) {
      this.setState({ fetching: true }, this._getUser)
    } else if (prevProps.userUuid && !userUuid) {
      this.setState({ user: { roles: {} } })
    }
  }

  _getUser = () => {
    const { userUuid, signOut, client } = this.props
    const query = isPublicSchema ? AdminUser : User

    client
      .query({ query, variables: { uuid: userUuid } })
      .then(({ data }) => {
        const user = isPublicSchema ? data.adminUser : data.user

        this.setState({ fetching: false }, () => {
          this._setUser({ ...user })
        })
      })
      .catch(error => {
        this.setState({ error: true, fetching: false })
        console.error(error)
        toast.error('An error occured. Try again')
        signOut()
      })
  }

  _setUser = user => {
    this.setState(state => ({ user: { ...state.user, ...user } }))
  }

  render() {
    const { children } = this.props
    const { isMounted, user, error, fetching } = this.state

    if (!isMounted || fetching || error) {
      return <SpinnerPage />
    }

    return (
      <UserContext.Provider
        value={{
          refetchUser: this._getUser,
          setUser: this._setUser,
          user,
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}

export default withAuth(withApollo(UserProvider))

const UserConsumer = UserContext.Consumer
export { UserConsumer }
