import React, { Fragment, useContext } from 'react'
import classnames from 'classnames'
import get from 'lodash/get'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'

import UserProvider from '@contexts/UserContext'
import BrandInfoProvider, { BrandInfoContext } from '@contexts/BrandInfoContext'
import { AuthProvider, AuthConsumer } from '@contexts/AuthContext'
import { AppProvider, AppContext } from '@contexts/AppContext'
import { ListProvider } from '@hooks/useList/context'
import { client } from '@utils/apolloConfig'

import ProtectedRoute from '@components/ProtectedRoute'
import LazyLoader from '@components/LazyLoader'
import Nav from '@components/Nav'
import Header from '@components/Layout/Header'
import isPublicSchema from '@utils/isPublicSchema'
import { HamburgerIcon } from '@assets/icons'

const Brand = React.lazy(() => import('../Brand'))
const LatestScans = React.lazy(() => import('../Brand/LatestScans'))
const Campaign = React.lazy(() => import('../Campaign'))
const PWAReleases = React.lazy(() => import('../PWAReleases'))
const PWAReleaseGroups = React.lazy(() => import('../PWAReleaseGroups'))
const Login = React.lazy(() => import('../Login'))
const Scan = React.lazy(() => import('../Scan'))
const RadioScan = React.lazy(() => import('../RadioScan'))
const VideoScan = React.lazy(() => import('../VideoScan'))
const TvScan = React.lazy(() => import('../TvScan'))
const Error404 = React.lazy(() => import('../Error404'))
const PersonalExperiences = React.lazy(() => import('../PersonalExperience'))
const Profile = React.lazy(() => import('../Profile'))
const Masquerade = React.lazy(() => import('../Masquerade'))
const UnlockAccess = React.lazy(() => import('../UnlockAccess'))
const User = React.lazy(() => import('../User'))
const AcceptInvitation = React.lazy(() => import('../User/AcceptInvitation'))
const Reports = React.lazy(() => import('../Reports'))
const OAuthPublic = React.lazy(() => import('../OAuth/Public'))
const OAuthTenant = React.lazy(() => import('../OAuth/Tenant'))

const LayoutWithNavbar = ({ children }) => {
  const { menuState, toggleMenu } = useContext(AppContext)
  const menuClassName = classnames('layout__menu', {
    '-opened': menuState,
  })

  return (
    <section className="layout__container">
      <aside className={menuClassName}>
        <Nav />
      </aside>
      <section
        className="layout__main"
        onClick={() => {
          menuState && toggleMenu(!menuState)
        }}
      >
        <Header />
        <div className="layout__main-body">{children}</div>
      </section>
      <button className="layout__menu-toggler" onClick={() => toggleMenu(!menuState)}>
        <HamburgerIcon className="layout__menu-toggler-icon" />
      </button>
    </section>
  )
}

const Routes = () => {
  const { features } = useContext(BrandInfoContext)

  return (
    <Switch>
      {isPublicSchema ? (
        <Redirect from="/campaigns" to="/brands" />
      ) : (
        <Redirect from="/brands" to="/campaigns" />
      )}
      <ProtectedRoute component={LazyLoader(Brand)} path="/brands" />
      <ProtectedRoute component={LazyLoader(LatestScans)} path="/latest" />
      <ProtectedRoute component={LazyLoader(PWAReleaseGroups)} path="/pwa_release_groups" />
      <ProtectedRoute component={LazyLoader(PWAReleases)} path="/pwa_releases" />
      <ProtectedRoute component={LazyLoader(Campaign)} path="/campaigns" />
      <ProtectedRoute component={LazyLoader(Scan)} path="/scans" />
      {get(features, 'radio_scans') && (
        <ProtectedRoute component={LazyLoader(RadioScan)} path="/radio_scans" />
      )}
      {get(features, 'video_scans') && (
        <ProtectedRoute component={LazyLoader(VideoScan)} path="/video_scans" />
      )}
      {get(features, 'tv_scans') && (
        <ProtectedRoute component={LazyLoader(TvScan)} path="/tv_scans" />
      )}
      {get(features, 'personal_experience') && (
        <ProtectedRoute component={LazyLoader(PersonalExperiences)} path="/personal_experiences" />
      )}
      <ProtectedRoute component={LazyLoader(Profile)} path="/profile" />
      <ProtectedRoute component={LazyLoader(Reports)} path="/reports" />
      {!isPublicSchema && <ProtectedRoute component={LazyLoader(User)} path="/users" />}
      {isPublicSchema && <Redirect exact={true} from="/" to="/brands" />}
      {!isPublicSchema && <Redirect exact={true} from="/" to="/campaigns" />}
      <Route component={LazyLoader(Error404)} />
    </Switch>
  )
}

export const Layout = () => (
  <Fragment>
    <Switch>
      <ApolloProvider client={client}>
        <AuthProvider>
          <Switch>
            <Route
              component={LazyLoader(AcceptInvitation)}
              exact={true}
              path="/accept_invitation/:token"
            />
            <Route component={LazyLoader(Masquerade)} exact={true} path="/masquerade/:token" />
            <Route component={LazyLoader(UnlockAccess)} exact={true} path="/unlock_access/:token" />
            {isPublicSchema && <Route component={LazyLoader(OAuthPublic)} path="/oauth" />}
            {!isPublicSchema && <Route component={LazyLoader(OAuthTenant)} path="/oauth" />}
            <Route component={LazyLoader(Login)} path="/login" />
            <AuthConsumer>
              {authState => {
                if (!authState.isAuth) {
                  return <Redirect exact={true} to="/login" />
                } else {
                  return (
                    <UserProvider>
                      <BrandInfoProvider>
                        <AppProvider>
                          <ListProvider>
                            <LayoutWithNavbar>
                              <Routes />
                            </LayoutWithNavbar>
                          </ListProvider>
                        </AppProvider>
                      </BrandInfoProvider>
                    </UserProvider>
                  )
                }
              }}
            </AuthConsumer>
          </Switch>
        </AuthProvider>
      </ApolloProvider>
    </Switch>
  </Fragment>
)

export default Layout
