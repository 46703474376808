export const initialState = {
  headerTitle: '',
  campaignName: '',
  brandListQuery: '',
  menuState: false,
}

export const SET_HEADER_TITLE = 'SET_HEADER_TITLE'
export const SET_CAMPAIGN_NAME = 'SET_CAMPAIGN_NAME'
export const TOGGLE_MENU = 'TOGGLE_MENU'

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_HEADER_TITLE: {
      return { ...state, headerTitle: payload }
    }
    case SET_CAMPAIGN_NAME: {
      return { ...state, campaignName: payload }
    }
    case TOGGLE_MENU: {
      return { ...state, menuState: !state.menuState }
    }
    default:
      return state
  }
}

export default reducer
