import gql from 'graphql-tag'

export default gql`
  query brandInfo {
    brandInfo {
      availableCampaignTypes
      features
      logoUrl
      name
    }
  }
`
