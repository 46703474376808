import gql from 'graphql-tag'

export default gql`
  query User($uuid: ID!) {
    user(uuid: $uuid) {
      email
      id
      role
    }
  }
`
