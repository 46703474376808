import mapValues from 'lodash/mapValues'

const FEATURE_VALUES = {
  disabled: 'disabled',
  enabled: 'enabled',
}

export const mapFeaturesValues = (
  features,
  truthy = FEATURE_VALUES.enabled,
  falsy = FEATURE_VALUES.disabled,
) =>
  mapValues(
    features,
    value => (value === FEATURE_VALUES.enabled || value === true ? truthy : falsy),
  )
