import gql from 'graphql-tag'

export default gql`
  mutation samMasquerade {
    samMasquerade(input: {}) {
      url
      token
    }
  }
`
