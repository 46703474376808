import React from 'react'

import withAuth from '@hocs/withAuth'
import { LogoutIcon } from '@assets/icons'

export const LogoutButton = ({ signOut }) => (
  <div className="layout__menu-link pointer" data-cy="signOut" onClick={signOut}>
    <LogoutIcon className="layout__menu-link-icon" />
    <div className="layout__menu-link-title">Logout</div>
  </div>
)

export default withAuth(LogoutButton)
