import React, { Fragment } from 'react'

import Links from './Links'
import Header from './Header'

export const Nav = () => (
  <Fragment>
    <Header />
    <Links />
  </Fragment>
)

export default Nav
