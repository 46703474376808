import React, { PureComponent } from 'react'
import { jwtDecode } from 'jwt-decode'

import portalRestAPI from '@utils/portalRestAPI'
import isPublicSchema from '@utils/isPublicSchema'
import SpinnerPage from '@components/SpinnerPage'

const AuthContext = React.createContext()
class AuthProvider extends PureComponent {
  state = {
    isMounted: false,
    isAuth: false,
    token: '',
    userUuid: '',
  }

  componentDidMount = () => {
    const token = localStorage.getItem('token')
    this.setState({ isMounted: true, isAuth: !!token, token })

    if (token) {
      this._setUser(token)
    }
  }

  _setUser = token => {
    try {
      const decodedToken = jwtDecode(token) || {}
      const { sub } = decodedToken

      if (!sub) {
        this._clearStateAndLocalStorage()
        return
      }

      localStorage.setItem('userUuid', sub)
      this.setState({ userUuid: sub })
    } catch (error) {
      this._clearStateAndLocalStorage()
    }
  }

  _signIn = ({ code, email, password }) => {
    const user = isPublicSchema
      ? { admin_user: { code, email, password } }
      : { user: { email, password } }

    return portalRestAPI('sign_in', {
      method: 'post',
      body: JSON.stringify(user),
    }).then(response => {
      const { token } = response

      this._setToken(token)
    })
  }

  _setToken = (token, callback) => {
    this.setState({ isAuth: true, token }, () => {
      this._setUser(token)
      localStorage.setItem('token', token)
      if (callback && typeof callback === 'function') {
        callback()
      }
    })
  }

  _signOut = () => {
    const { token } = this.state

    return portalRestAPI('sign_out', {
      method: 'delete',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(this._clearStateAndLocalStorage)
      .catch(this._clearStateAndLocalStorage)
  }

  _forceSignOut = async () => {
    await this._signOut()
    window.location.reload(true)
  }

  _clearStateAndLocalStorage = () => {
    this.setState({ isAuth: false, token: '', userUuid: '' }, () => {
      localStorage.removeItem('userUuid')
      localStorage.removeItem('token')
      localStorage.removeItem('persistedListState')
    })
  }

  render() {
    const { children } = this.props
    const { isAuth, isMounted, token, userUuid } = this.state

    if (!isMounted) {
      return <SpinnerPage />
    }

    return (
      <AuthContext.Provider
        value={{
          isAuth,
          setToken: this._setToken,
          signIn: this._signIn,
          signOut: this._forceSignOut,
          token,
          userUuid,
        }}
      >
        {children}
      </AuthContext.Provider>
    )
  }
}

const AuthConsumer = AuthContext.Consumer
export { AuthProvider, AuthConsumer }
