import { merge, update, set, del } from 'object-path-immutable'
import xor from 'lodash/xor'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'

import listInitialState from './listInitialState'

const reducer = (state = {}, { type, payload, meta = {} }) => {
  const { listId, listName } = meta

  switch (type) {
    case 'init': {
      return { ...state, ...payload }
    }
    case 'setPage': {
      return merge(state, [listName, listId], {
        page: payload,
      })
    }
    case 'setPerPage': {
      return merge(state, [listName, listId], {
        page: 1,
        perPage: payload,
      })
    }
    case 'selectItem': {
      return update(state, [listName, listId, 'selectedItems'], v => xor(v, [payload]))
    }
    case 'selectItemsFromPage': {
      return update(state, [listName, listId, 'selectedItems'], (v = []) =>
        uniq([...v, ...payload])
      )
    }
    case 'clearSelected': {
      return merge(state, [listName, listId], {
        selectedItems: [],
      })
    }
    case 'clearSelectedFromPage': {
      const selectedItems = get(state, [listName, listId, 'selectedItems'], []).filter(
        item => !payload.includes(item)
      )

      return merge(state, [listName, listId], {
        selectedItems,
      })
    }
    case 'setSearch': {
      return merge(state, [listName, listId], {
        search: payload,
      })
    }
    case 'resetList': {
      return set(state, [listName, listId], listInitialState)
    }
    case 'setFilter': {
      return merge(state, [listName, listId], {
        filters: payload,
        page: 1,
      })
    }
    case 'resetFilter': {
      const updatedState = del(state, `${listName}.${listId}.filters.${payload}`)
      return set(updatedState, [listName, listId, 'page'], 1)
    }
    case 'setQuickFilters': {
      if (!payload) {
        return state
      }

      const { filtersToSet = {}, filtersToRemove = {} } = payload
      const filtersGroup = get(state, [listName, listId, 'filters'], {})
      const filteredList = mapValues(filtersGroup, (filter, key) => {
        if (typeof filter !== 'object') {
          return filter
        }
        return pickBy(filter, (_, filterKey) => {
          return filtersToRemove[key] ? !filtersToRemove[key].includes(filterKey) : true
        })
      })

      const updatedState = set(
        state,
        [listName, listId, 'filters'],
        merge(filteredList, {}, filtersToSet)
      )

      return set(updatedState, [listName, listId, 'page'], 1)
    }
    case 'resetFilters': {
      return set(state, [listName, listId, 'filters'], {})
    }
    default:
      return state
  }
}

export default reducer
