import gql from 'graphql-tag'

export default gql`
  query AdminUser($uuid: ID!) {
    adminUser(uuid: $uuid) {
      email
      id
      oneTimePasswordActive
    }
  }
`
