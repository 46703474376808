import React, { Fragment, useContext } from 'react'
import get from 'lodash/get'
import { NavLink } from 'react-router-dom'

import { BrandInfoContext } from '@contexts/BrandInfoContext'
import { AppContext } from '@contexts/AppContext'
import isPublicSchema from '@utils/isPublicSchema'
import {
  CameraIcon,
  BrandsIcon,
  UserIcon,
  PWAIcon,
  ImagesIcon,
  VideoCameraIcon,
  CSVFileIcon,
  UsersIcon,
  TvIcon,
} from '@assets/icons'

import LogoutButton from './LogoutButton'
import Sam from './Sam'

const Links = () => {
  const { toggleMenu } = useContext(AppContext)
  const { features } = useContext(BrandInfoContext)
  const handleMenuToggle = () => toggleMenu()

  return (
    <section className="layout__menu-content">
      {isPublicSchema ? (
        <Fragment>
          <NavLink
            activeClassName="layout__menu-link -active"
            className="layout__menu-link"
            onClick={handleMenuToggle}
            to="/brands"
          >
            <BrandsIcon className="layout__menu-link-icon" />
            <div className="layout__menu-link-title">Brands</div>
          </NavLink>
          <NavLink
            activeClassName="layout__menu-link -active"
            className="layout__menu-link"
            onClick={handleMenuToggle}
            to="/latest/scans"
          >
            <CameraIcon className="layout__menu-link-icon" />
            <div className="layout__menu-link-title">Latest scans</div>
          </NavLink>
          <NavLink
            activeClassName="layout__menu-link -active"
            className="layout__menu-link"
            onClick={handleMenuToggle}
            to="/latest/radio"
          >
            <ImagesIcon className="layout__menu-link-icon" />
            <div className="layout__menu-link-title">Latest radio scans</div>
          </NavLink>
          <NavLink
            activeClassName="layout__menu-link -active"
            className="layout__menu-link"
            onClick={handleMenuToggle}
            to="/latest/tv"
          >
            <TvIcon className="layout__menu-link-icon" />
            <div className="layout__menu-link-title">Latest TV scans</div>
          </NavLink>
          <NavLink
            activeClassName="layout__menu-link -active"
            className="layout__menu-link"
            onClick={handleMenuToggle}
            to="/latest/video"
          >
            <VideoCameraIcon className="layout__menu-link-icon" />
            <div className="layout__menu-link-title">Latest video scans</div>
          </NavLink>
          <NavLink
            activeClassName="layout__menu-link -active"
            className="layout__menu-link"
            onClick={handleMenuToggle}
            to="/profile"
          >
            <UserIcon className="layout__menu-link-icon" />
            <div className="layout__menu-link-title">Profile</div>
          </NavLink>
          <NavLink
            activeClassName="layout__menu-link -active"
            className="layout__menu-link"
            onClick={handleMenuToggle}
            to="/pwa_release_groups"
          >
            <PWAIcon className="layout__menu-link-icon" />
            <div className="layout__menu-link-title">PWA Release Groups</div>
          </NavLink>
          <LogoutButton />
        </Fragment>
      ) : (
        <Fragment>
          <NavLink
            activeClassName="layout__menu-link -active"
            className="layout__menu-link"
            onClick={handleMenuToggle}
            to="/campaigns"
          >
            <ImagesIcon className="layout__menu-link-icon" />
            <div className="layout__menu-link-title">Campaigns</div>
          </NavLink>
          <NavLink
            activeClassName="layout__menu-link -active"
            className="layout__menu-link"
            onClick={handleMenuToggle}
            to="/scans"
          >
            <CameraIcon className="layout__menu-link-icon" />
            <div className="layout__menu-link-title">Scans</div>
          </NavLink>
          {get(features, 'video_scans') && (
            <NavLink
              activeClassName="layout__menu-link -active"
              className="layout__menu-link"
              onClick={handleMenuToggle}
              to="/video_scans"
            >
              <VideoCameraIcon className="layout__menu-link-icon" />
              <div className="layout__menu-link-title">Video scans</div>
            </NavLink>
          )}
          {get(features, 'radio_scans') && (
            <NavLink
              activeClassName="layout__menu-link -active"
              className="layout__menu-link"
              onClick={handleMenuToggle}
              to="/radio_scans"
            >
              <ImagesIcon className="layout__menu-link-icon" />
              <div className="layout__menu-link-title">Radio scans</div>
            </NavLink>
          )}
          {get(features, 'tv_scans') && (
            <NavLink
              activeClassName="layout__menu-link -active"
              className="layout__menu-link"
              onClick={handleMenuToggle}
              to="/tv_scans"
            >
              <TvIcon className="layout__menu-link-icon" />
              <div className="layout__menu-link-title">TV scans</div>
            </NavLink>
          )}
          {get(features, 'personal_experience') && (
            <NavLink
              activeClassName="layout__menu-link -active"
              className="layout__menu-link"
              onClick={handleMenuToggle}
              to="/personal_experiences"
            >
              <VideoCameraIcon className="layout__menu-link-icon" />
              <div className="layout__menu-link-title">Personal Experiences</div>
            </NavLink>
          )}
          {get(features, 'sam') && <Sam />}
          <NavLink
            activeClassName="layout__menu-link -active"
            className="layout__menu-link"
            onClick={handleMenuToggle}
            to="/users"
          >
            <UsersIcon className="layout__menu-link-icon" />
            <div className="layout__menu-link-title">Users</div>
          </NavLink>
          <NavLink
            activeClassName="layout__menu-link -active"
            className="layout__menu-link"
            onClick={handleMenuToggle}
            to="/reports"
          >
            <CSVFileIcon className="layout__menu-link-icon" />
            <div className="layout__menu-link-title">Reports</div>
          </NavLink>

          <NavLink
            activeClassName="layout__menu-link -active"
            className="layout__menu-link"
            onClick={handleMenuToggle}
            to="/profile"
          >
            <UserIcon className="layout__menu-link-icon" />
            <div className="layout__menu-link-title">Profile</div>
          </NavLink>
          <LogoutButton />
        </Fragment>
      )}
    </section>
  )
}

export default Links
