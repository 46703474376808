import React, { createContext, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'

import reducer from './reducer'

const initialState = {}

export const ListContext = createContext(initialState)

export const ListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    JSON.parse(localStorage.getItem('persistedListState')) || initialState,
  )
  useEffect(
    () => {
      localStorage.setItem('persistedListState', JSON.stringify(state))
    },
    [state],
  )

  return <ListContext.Provider value={{ state, dispatch }}>{children}</ListContext.Provider>
}

ListProvider.propTypes = {
  children: PropTypes.node,
}
