import React, { Suspense, PureComponent } from 'react'

import SpinnerPage from './SpinnerPage'

function LazyLoader(WrappedComponent) {
  return class Wrapped extends PureComponent {
    render() {
      return (
        <Suspense fallback={<SpinnerPage />}>
          <WrappedComponent {...this.props} />
        </Suspense>
      )
    }
  }
}

export default LazyLoader
